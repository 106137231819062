// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("/opt/build/repo/src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-code-of-conduct-js": () => import("/opt/build/repo/src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-conference-js": () => import("/opt/build/repo/src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-edu-summit-js": () => import("/opt/build/repo/src/pages/edu-summit.js" /* webpackChunkName: "component---src-pages-edu-summit-js" */),
  "component---src-pages-event-js": () => import("/opt/build/repo/src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-schedule-js": () => import("/opt/build/repo/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-sponsors-js": () => import("/opt/build/repo/src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-sponsorship-js": () => import("/opt/build/repo/src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-tutorials-js": () => import("/opt/build/repo/src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-venue-js": () => import("/opt/build/repo/src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-pages-workshops-js": () => import("/opt/build/repo/src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-pages-young-coders-js": () => import("/opt/build/repo/src/pages/young-coders.js" /* webpackChunkName: "component---src-pages-young-coders-js" */)
}

